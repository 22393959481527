import React from 'react';
import { Routes, Route } from 'react-router-dom';

// Import des pages (à créer)
const Home = React.lazy(() => import('./pages/Home'));
const Presentation = React.lazy(() => import('./pages/Presentation'));
const Pourquoi = React.lazy(() => import('./pages/Pourquoi'));
const Services = React.lazy(() => import('./pages/Services'));
const Realisations = React.lazy(() => import('./pages/Realisations'));
const Contact = React.lazy(() => import('./pages/Contact'));
const MentionsLegales = React.lazy(() => import('./pages/MentionsLegales'));
const NoahReseaux = React.lazy(() => import('./pages/NoahReseaux'));
const ServiceExpertisePage = React.lazy(() => import('./pages/ServiceExpertisePage'));

const AppRoutes = () => {
    return (
        <React.Suspense fallback={<div>Chargement...</div>}>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/presentation" element={<Presentation />} />
                <Route path="/pourquoi" element={<Pourquoi />} />
                <Route path="/nos-services" element={<Services />} />
                <Route path="/realisations" element={<Realisations />} />
                <Route path="/contactez-nous" element={<Contact />} />
                <Route path="/mentions-legales" element={<MentionsLegales />} />
                <Route path="/noah-reseaux" element={<NoahReseaux />} />
                <Route path="/expertise-de-votre-bad" element={<ServiceExpertisePage />} />
                <Route path="*" element={<div>Page non trouvée</div>} />
            </Routes>
        </React.Suspense>
    );
};

export default AppRoutes; 