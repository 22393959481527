import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NoahLogo from './NoahLogo';
import { FaChevronDown, FaEnvelope, FaHouse, FaNetworkWired, FaShield, FaShieldAlt } from 'react-icons/fa6';
import { BsFillXDiamondFill } from "react-icons/bs";

const Header = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const [showMenu, setShowMenu] = useState(false);
    const [showServiceMenu, setShowServiceMenu] = useState(false);
    const [showPresentationMenu, setShowPresentationMenu] = useState(false);
    const [showWhyMenu, setShowWhyMenu] = useState(false);
    const [activePage, setActivePage] = useState('');

    useEffect(() => {
        setActivePage(location.pathname.replaceAll('/', ''));
    }, [location]);

    useEffect(() => {
        setShowMenu(false);
    }, [activePage]);

    return (
        <nav className="bg-white border-gray-200  fixed top-0 left-0 w-full h-[72px] z-30 shadow-md mainHeader bg-opacity-80">
            <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4 h-[72px]">
                <NoahLogo />
                
                <button 
                    onClick={() => setShowMenu(!showMenu)} 
                    className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg xl:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
                >
                    <span className="sr-only">Ouvrir le menu</span>
                    <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
                    </svg>
                </button>

                {/* Menu mobile */}
                <div className={`${showMenu ? 'block' : 'hidden'} w-full xl:hidden`}>
                    <ul className="uppercase font-medium flex flex-col p-4 mt-3 border border-gray-100 rounded-lg bg-white bg-opacity-70 backdrop-filter backdrop-blur-lg">
                        <li>
                            <Link to="/" className={`${activePage === '' ? 'text-yellow-600' : 'text-gray-900'} block py-2 px-3 hover:text-yellow-700`}>
                                {t('Accueil')}
                            </Link>
                        </li>
                        <li>
                            <Link to="/presentation" className={`${activePage === 'presentation' ? 'text-yellow-600' : 'text-gray-900'} block py-2 px-3 hover:text-yellow-700`}>
                                {t('Qui sommes-nous ?')}
                            </Link>
                        </li>
                        <li>
                            <Link to="/pourquoi" className={`${activePage === 'pourquoi' ? 'text-yellow-600' : 'text-gray-900'} block py-2 px-3 hover:text-yellow-700`}>
                                {t('Pourquoi ?')}
                            </Link>
                        </li>
                        <li>
                            <Link to="/nos-services" className={`${activePage === 'nos-services' ? 'text-yellow-600' : 'text-gray-900'} block py-2 px-3 hover:text-yellow-700`}>
                                {t('Nos Services')}
                            </Link>
                        </li>
                        {/* <li>
                            <Link to="/nos-tarifs" className={`${activePage === 'nos-tarifs' ? 'text-yellow-600' : 'text-gray-900'} block py-2 px-3 hover:text-yellow-700`}>
                                {t('Nos Tarifs')}
                            </Link>
                        </li> */}
                        <li>
                            <Link to="/realisations" className={`${activePage === 'realisations' ? 'text-yellow-600' : 'text-gray-900'} block py-2 px-3 hover:text-yellow-700`}>
                                {t('Nos Réalisations')}
                            </Link>
                        </li>
                        <li>
                            <Link to="https://noah-residences.com/" className={`${activePage === 'noah-residences' ? 'text-yellow-600' : 'text-gray-900'} block py-2 px-3 hover:text-yellow-700`}>
                                NOAH <span className='text-yellow-500'>RESIDENCES</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/noah-reseaux" className={`${activePage === 'noah-reseaux' ? 'text-yellow-600' : 'text-gray-900'} block py-2 px-3 hover:text-yellow-700`}>
                                NOAH <span className='text-yellow-500'>RÉSEAUX</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/contactez-nous" className={`${activePage === 'contactez-nous' ? 'text-yellow-600' : 'text-gray-900'} block py-2 px-3 hover:text-yellow-700`}>
                                Contactez-nous
                            </Link>
                        </li>
                    </ul>
                </div>

                {/* Menu desktop */}
                <div className="hidden xl:block ">
                    <ul className=" flex items-center space-x-4 -mt-2 text-sm uppercase" style={{fontWeight:'400'}}>
                        <li>
                            <Link to="/presentation" className={`${activePage === 'presentation' ? 'text-yellow-600' : 'text-gray-900'} hover:text-yellow-700 relative group`}>
                                Présentation
                                <div className={`${activePage === 'presentation' ? 'opacity-100' : 'opacity-0'} w-20 h-[2px] bg-yellow-500  absolute -bottom-2 left-0 transition-opacity duration-300`}></div>
                            </Link>
                        </li>

                        <li className="relative group">
                            <div 
                                onMouseEnter={() => setShowWhyMenu(true)} 
                                onMouseLeave={() => setShowWhyMenu(false)}
                            >
                                <Link to="/pourquoi" className={`${activePage === 'pourquoi' ? 'text-yellow-600' : 'text-gray-900'} hover:text-yellow-700 flex items-center`}>
                                    Pourquoi ?
                                    <FaChevronDown className="ml-1" />
                                    <div className={`${activePage === 'pourquoi' ? 'opacity-100' : 'opacity-0'} w-20 h-[2px] bg-yellow-500  absolute -bottom-2 left-0 transition-opacity duration-300`}></div>
                                </Link>

                                <ul className={`${showWhyMenu ? 'block' : 'hidden'} absolute left-0 mt-0 w-64 bg-white shadow-lg rounded-lg py-2`}>
                                    <li>
                                        <Link to="/pourquoi#qualite-vie" className="block px-4 py-2 text-gray-900 hover:text-yellow-700 hover:bg-gray-100">
                                            Amélioration de votre qualité de vie
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/pourquoi#nouveaux-risques" className="block px-4 py-2 text-gray-900 hover:text-yellow-700 hover:bg-gray-100">
                                            De nouveaux risques pour notre civilisation
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/pourquoi#tensions-militaires" className="block px-4 py-2 text-gray-900 hover:text-yellow-700 hover:bg-gray-100">
                                            Des tensions militaires croissantes
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/pourquoi#effondrement-economique" className="block px-4 py-2 text-gray-900 hover:text-yellow-700 hover:bg-gray-100">
                                            Effondrement économique mondial
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/pourquoi#solutions-innovantes" className="block px-4 py-2 text-gray-900 hover:text-yellow-700 hover:bg-gray-100">
                                            Des solutions innovantes
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </li>

                        <li className="relative group">
                            <div 
                                onMouseEnter={() => setShowServiceMenu(true)} 
                                onMouseLeave={() => setShowServiceMenu(false)}
                            >
                                <Link to="/nos-services" className={`${activePage === 'nos-services' ? 'text-yellow-600' : 'text-gray-900'} hover:text-yellow-700 flex items-center`}>
                                    Nos services
                                    <FaChevronDown className="ml-1" />
                                    <div className={`${activePage === 'nos-services' ? 'opacity-100' : 'opacity-0'} w-20 h-[2px] bg-yellow-500 absolute -bottom-2 left-0 transition-opacity duration-300`}></div>
                                </Link>

                                <ul className={`${showServiceMenu ? 'block' : 'hidden'} absolute left-0 mt-0 w-64 bg-white shadow-lg rounded-lg py-2`}>
                                    {/* <li>
                                        <Link to="/nos-services#installation-photovoltaique" className="block px-4 py-2 text-gray-900 hover:text-yellow-700 hover:bg-gray-100">
                                            Installation de systèmes photovoltaïques
                                        </Link>
                                    </li> */}
                                    <li>
                                        <Link to="/nos-services#abris-nrbc" className="block px-4 py-2 text-gray-900 hover:text-yellow-700 hover:bg-gray-100">
                                            Conception et réalisation d'abris sécurisés
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/expertise-de-votre-bad" className="block px-4 py-2 text-gray-900 hover:text-yellow-700 hover:bg-gray-100">
                                            Expertise complète
                                        </Link>
                                    </li>
                                    {/* <li>
                                        <Link to="/nos-services#visite-conseils" className="block px-4 py-2 text-gray-900 hover:text-yellow-700 hover:bg-gray-100">
                                            Visite-conseils
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/nos-services#consultation-telephonique" className="block px-4 py-2 text-gray-900 hover:text-yellow-700 hover:bg-gray-100">
                                            Conseils téléphoniques
                                        </Link>
                                    </li> */}
                                </ul>
                            </div>
                        </li>

                        {/* <li>
                            <Link to="/nos-tarifs" className={`${activePage === 'nos-tarifs' ? 'text-yellow-600' : 'text-gray-900'} hover:text-yellow-700 relative group`}>
                                Nos tarifs
                                <div className={`${activePage === 'nos-tarifs' ? 'opacity-100' : 'opacity-0'} w-20 h-[2px] bg-yellow-500  absolute -bottom-2 left-0 transition-opacity duration-300`}></div>
                            </Link>
                        </li> */}

                        <li>
                            <Link to="/realisations" className={`${activePage === 'realisations' ? 'text-yellow-600' : 'text-gray-900'} hover:text-yellow-700 relative group`}>
                                {t('Nos Réalisations')}
                                <div className={`${activePage === 'realisations' ? 'opacity-100' : 'opacity-0'} w-20 h-[2px] bg-yellow-500  absolute -bottom-2 left-0 transition-opacity duration-300`}></div>
                            </Link>
                        </li>

                        <li className="relative group">
                            <div 
                                onMouseEnter={() => setShowPresentationMenu(true)} 
                                onMouseLeave={() => setShowPresentationMenu(false)}
                            >
                                <div className="text-gray-900 hover:text-yellow-700 flex items-center cursor-pointer">
                                    <BsFillXDiamondFill className="mr-2" />
                                    {t("L'univers")} <span className="text-yellow-500 ml-1">NOAH</span>
                                    <FaChevronDown className="ml-1" />
                                </div>

                                <ul className={`${showPresentationMenu ? 'block' : 'hidden'} absolute left-0 mt-0 w-64 bg-white shadow-lg rounded-lg py-2`}>
                                    <li>
                                        <Link to="/" className="block px-4 py-2 text-gray-900 hover:text-yellow-700 hover:bg-gray-100">
                                            <FaShield className="inline-block mr-2" />
                                            NOAH <span className="text-yellow-500">RÉSILIENCE</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="https://noah-residences.com/" className="block px-4 py-2 text-gray-900 hover:text-yellow-700 hover:bg-gray-100">
                                            <FaHouse className="inline-block mr-2" />
                                            NOAH <span className="text-yellow-500">RÉSIDENCES</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/noah-reseaux" className="block px-4 py-2 text-gray-900 hover:text-yellow-700 hover:bg-gray-100">
                                            <FaNetworkWired className="inline-block mr-2" />
                                            NOAH <span className="text-yellow-500">RÉSEAUX</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </li>

                        <li>
                            <Link to="/contactez-nous" className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-yellow-600 rounded-md hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500">
                                <FaEnvelope className="mr-2" />
                                Contactez-nous
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Header; 