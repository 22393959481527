import React from 'react';
import ReactDOM from 'react-dom';  // Changé de 'react-dom/client'
import { BrowserRouter } from 'react-router-dom';
import './styles/global.css';
import App from './App';

ReactDOM.render(  // Changé de ReactDOM.createRoot().render()
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);