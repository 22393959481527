import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SimpleReactLightbox from 'simple-react-lightbox';

// Components
import Header from './components/Header';
import Footer from './components/Footer';
import ReturnToTop from './components/ReturnToTop';
import AppRoutes from './routes';

// Configuration i18n
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Configuration de base i18n
// i18n.use(initReactI18next).init({
//   resources: {
//     fr: {
//       translation: {
//         'Tous droits réservés': 'Tous droits réservés',
//         'Mentions légales': 'Mentions légales',
//         'Accueil': 'Accueil',
//         'Qui sommes-nous ?': 'Qui sommes-nous ?',
//         'Contact': 'Contact',
//         "L'univers": "L'univers",
//         'Nos Réalisations': 'Nos Réalisations'
//       }
//     }
//   },
//   lng: 'fr',
//   fallbackLng: 'fr',
//   interpolation: {
//     escapeValue: false
//   }
// });

function App() {
  const location = useLocation();

  return (
    <>
      <SimpleReactLightbox>
        <Header />
        <main className="pt-[68px]">
          <AppRoutes />
        </main>
        {location.pathname !== '/' && <Footer />}
        <ReturnToTop />
      </SimpleReactLightbox>
    </>
  );
}

export default App; 