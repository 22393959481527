import React, { useState, useEffect } from 'react';
import { FaArrowUp } from 'react-icons/fa6';
import { Zoom } from 'react-reveal';

const ReturnToTop = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const toggleVisibility = () => {
            if (window.pageYOffset > window.innerHeight) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener("scroll", toggleVisibility);

        return () => {
            window.removeEventListener("scroll", toggleVisibility);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    return (
        isVisible && (
            <Zoom>
                <div className='fixed bottom-0 right-0 mb-4 mr-4 z-50'>
                    <button 
                        onClick={scrollToTop}
                        className='text-3xl text-gray-800 dark:text-gray-400 hover:text-lime-500'
                        aria-label="Retour en haut de la page"
                    >
                        <FaArrowUp />
                    </button>
                </div>
            </Zoom>
        )
    );
};

export default ReturnToTop; 